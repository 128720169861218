import React, { useEffect } from "react"
import { Link } from "gatsby"
import { useWindowSize } from "react-use"
import Layout from "../components/layout.js"
import Seo from "../components/seo.js"

import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import { WelcomeLayout } from "../styles/common.js"
import { useInitialFocus } from "../hooks/index.js"

import Logo from "../lib/logo.js"
import { TaliHappy } from "../lib/icons/Tali.js"

import { useTeacherStore } from "../../store.js"

const WelcomeIndexPage = () => {
  const focusRef = useInitialFocus()
  const { height } = useWindowSize()
  const { teacher } = useTeacherStore((store) => store)

  useEffect(() => {
    console.log("teacher object: ", teacher)
  }, [teacher])

  return (
    <Layout layoutHeight={height}>
      <Seo title="Willkommen bei Talidu" />
      <WelcomeLayout>
        <TaliHappy width={240} height={240} />
        <Logo />
        <p className="slogan">Lesen und Schreiben lernen</p>
        <p className="question">Ich bin:</p>
        <nav>
          <Link ref={focusRef} className="button" to="/student">
            ein Kind
          </Link>
          <Link style={{ margin: "1rem" }} className="button" to="/teacher">
            eine Lehrkraft
          </Link>
          {/*<Link*/}
          {/*  className="button"*/}
          {/*  to="/parent"*/}
          {/*>*/}
          {/*  Eltern*/}
          {/*</Link>*/}
        </nav>
      </WelcomeLayout>
    </Layout>
  )
}

export default WelcomeIndexPage
